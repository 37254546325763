<template>
  <div>
    <h1>Infos zum Verein</h1>
    <p>Wir sind ein eingetragener Verein. Unsere Satzung könnt ihr hier einsehen.</p>
    <ul>
      <li><a href="https://chantiervocal-public.s3.amazonaws.com/Satzung_Juli_23_Chantier_Vocal.pdf" download>Satzung (Stand: Juli 2023)</a></li>
    </ul>
    <h2>Kontoverbindung</h2>
    <p>
      Chantier Vocal e.V.<br>
      Deutsche Skatbank<br>
      IBAN: DE18 8306 5408 0005 3213 36<br>
      BIC: GENO DEF1 SLR
    </p>
    <h2>Datenschutz</h2>
    <p>Als Vereinsmitglieder habt ihr ein Recht darauf zu wissen, welche Daten von euch erhoben werden und wie diese verwendet werden.</p>
    <ul>
      <li><a href="https://chantiervocal-public.s3.amazonaws.com/Datenschutzinformationen-2024-04-01.pdf" download>Datenschutzinformationen</a></li>
      <li><a href="https://chantiervocal-public.s3.amazonaws.com/Verarbeitungstätigkeiten.pdf" download>Verarbeitungstätigkeiten</a></li>
    </ul>
  </div>
</template>

<script>
</script>
