<template>
  <sync-form v-bind="formdata" v-on:ok="ok">
    <div class="required">
      <label>Name</label>
      <input v-model="u.name">
    </div>
    <div class="required">
      <label>Email</label>
      <input type="email" v-model="u.email">
    </div>
    <div class="required">
      <label>Tel</label>
      <input type="tel" v-model="u.tel">
    </div>
    <div class="required">
      <label>Stimme</label>
      <select v-model="u.section">
        <option>Bass</option>
        <option>Tenor</option>
        <option>Alt</option>
        <option>Sopran</option>
      </select>
    </div>
    <div>
      <label>Geburtstag (yyyy-mm-dd)</label>
      <input type="text" v-model="u.birthday">
    </div>
    <div>
      <label>Adresse</label>
      <input v-model="u.address">
    </div>
    <div>
      <label>Was ich sonst noch sagen wollte</label>
      <input v-model="u.extraInfo">
    </div>
    <div v-if="inputType !== 'updateSelf'">
      <label>Rolle</label>
      <select label="Rolle" v-model="u.role">
        <option value="normal">Aktiv</option>
        <option value="inactive">Inaktiv</option>
        <option value="passive">Passiv</option>
        <option value="director">Vorstand</option>
        <option value="attndtaker">Anwesensheitsbeauftragte(r)</option>
      </select>
    </div>
  </sync-form>
</template>

<script>
import SynchronousForm from '@/components/Generic/SynchronousForm'

export default {
  name: 'ChoirMemberForm',
  components: {
    'sync-form': SynchronousForm,
  },
  props: ['member', 'inputType'],
  data () {
    return {
      u: {
        birthday: '',
        ...this.member
      }
    }
  },
  methods: {
    ok: function () {
      if (this.inputType === 'create') {
        this.u.birthday = ''
        this.u.name = ''
        this.u.email = ''
        this.u.tel = ''
        this.u.section = ''
        this.u.address = ''
        this.u.extraInfo = ''
        this.u.role = 'normal'
      }
      this.$emit('refresh')
    }
  },
  computed: {
    formdata: function () {
      let payload = {
        name: this.u.name,
        email: this.u.email,
        tel: this.u.tel,
        section: this.u.section,
        address: this.u.address,
        extraInfo: this.u.extraInfo,
        role: this.u.role,
        userId: this.u.userId
      }
      if (this.u.birthday) {
        payload['birthday'] = this.u.birthday
      }

      return {
        endpoint: '/users/' + this.inputType,
        payload: payload,
        submitText: this.inputType === 'create' ? 'Sänger hinzufügen' : 'Aktualisieren',
        successMsg: this.inputType === 'create' ? 'Sänger erfolgreich hinzugefügt' : 'Erfolgreich aktualisiert',
        validationErr: this.validationErr
      }
    },
    validationErr: function () {
      if (this.u.birthday) {
        const re = /\d\d\d\d-\d\d-\d\d/
        if (!re.test(this.u.birthday)) {
          return 'Dieser Geburtstag ist kein Datum'
        }
      }
      return (this.u.tel && this.u.email && this.u.name && this.u.section) ? '' : 'Pflichtfeld fehlt'
    }
  }
}
</script>

<style scoped>
form {
  max-width: 20em;
}
</style>
