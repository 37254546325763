<template>
  <div>
    <div v-if="director">
      <h1>Chorleitung</h1>
      <member :member="director" v-on:refresh="syncMembers"></member>
    </div>
    <div class="sections">
      <div v-for="sec in activeMembersBySections" :key="sec.section" class="section">
        <h1>{{sec.section}}</h1>
        <member v-for="member in sec.members" :member="member" :key="member.userId" v-on:refresh="syncMembers"></member>
      </div>
    </div>
    <h1>Inaktive/Ehemalige</h1>
    <member v-for="member in inactiveMembers" :member="member" :key="member.userId" v-on:refresh="syncMembers"></member>
    <div v-if="isMember">
      <h1>Passive Vereinsmitglieder</h1>
      <member v-for="member in passiveMembers" :member="member" :key="member.userId" v-on:refresh="syncMembers"></member>
    </div>
    <div v-if="isDirector">
      <hr>
      <toggle>
        <template v-slot:title>Sänger hinzufügen</template>
        <create-member :member="newUser"
                     v-on:refresh="this.syncMembers"
                     inputType="create">
        </create-member>
      </toggle>
      <toggle>
        <template v-slot:title>Sänger löschen</template>
        <delete-member :members="members" v-on:ok="syncMembers"></delete-member>
      </toggle>
    </div>
  </div>
</template>

<script>
import ChoirMemberComponent from './ChoirMember/Component'
import ChoirMemberForm from './ChoirMember/Form'
import ChoirMemberDelete from './ChoirMember/Delete'
import FoldingToggleComponent from '@/components/Generic/FoldingToggleComponent'
import { mapState, mapActions } from 'pinia'
import { useMembersStore, useUserStore } from '@/stores'

export default {
  name: 'ChoirMembersPage',
  components: {
    'member': ChoirMemberComponent,
    'create-member': ChoirMemberForm,
    'delete-member': ChoirMemberDelete,
    'toggle': FoldingToggleComponent
  },
  data () {
    return {
      newUser: {
        name: '',
        email: '',
        tel: '',
        section: '',
        birthday: '',
        address: '',
        extraInfo: '',
        active: true
      },
      picked: null
    }
  },
  methods: {
    syncCustom: function() {
      console.log("syncCustom")
    },
    ...mapActions(useMembersStore, ['syncMembers'])
  },
  computed: {
    ...mapState(useUserStore, [
      'isLoggedIn',
      'isDirector',
      'isMember',
      ]),
    ...mapState(useMembersStore, [
      'members',
      'activeMembersBySections',
      'inactiveMembers',
      'passiveMembers',
      'director'
    ])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.sections {
  margin-top: 1em;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section {
  width: 25em;
}
</style>
