<template>
  <div>
    <h1>Mails</h1>
    <sync-form v-bind="formdata" v-on:ok="ok">
      <div class="row">
        <div id="recipients">
          <label>Empfänger</label>
          <select v-model="recipientType">
            <option>Chor</option>
            <option>Bass</option>
            <option>Tenor</option>
            <option>Alt</option>
            <option>Sopran</option>
            <option>Alle Vereinsmitglieder</option>
            <option>Einzelne</option>
            <!-- <option v-if="isDirector && asDirector">Newsletter-Verteiler</option> -->
          </select>
        </div>
        <div v-if="isDirector">
          <label>Absender</label>
          <checkbox v-model="sendAsDirector" label="Vorstand"></checkbox>
        </div>
      </div>

      <transition name="fadeIn">
        <div class="row" v-if="recipientType === 'Einzelne'">
          <div v-for="sec in activeUsersBySections" :key="sec.section" class="section">
            <h3>{{sec.section}}</h3>
            <div v-for="member in sec.members" :key="member.userId" class="checkboxes">
              <checkbox :label="member.name" :arrValue="member.userId" v-model="recipientIds"></checkbox>
            </div>
          </div>
        </div>
      </transition>
      <div>
        <label for="subject">Titel</label>
        <input id="subject" v-model="subject" placeholder="Worum geht's?">
      </div>
      <div>
        <label for="body">Text</label>
        <textarea v-model="body" placeholder="Schreib was Nettes. Der Absender wird automatisch eingefügt."></textarea>
      </div>
      <div>
        <label>Anhang (mehrere mit Strg-Klick auswählen)</label>
        <input id="attachments" v-on:change="setAttachments" type="file" multiple>
      </div>
    </sync-form>
  </div>
</template>

<script>
import CheckboxComponent from '@/components/Generic/CheckboxComponent'
import SynchronousForm from '@/components/Generic/SynchronousForm'
import { mapState } from 'pinia'
import { useUserStore, useMembersStore } from '@/stores'

export default {
  name: 'MailSend',
  components: {
    'sync-form': SynchronousForm,
    'checkbox': CheckboxComponent
  },
  data () {
    const userStore = useUserStore()
    return {
      subject: '',
      body: '',
      sendAsDirector: userStore.isDirector,
      recipientType: 'Chor',
      recipientIds: [],
      attachments: []
    }
  },
  methods: {
    ok: function () {
      this.body = ''
      this.subject = ''
    },
    setAttachments: function (ev) {
      this.attachments = []
      for (var i = 0; i < ev.target.files.length; i++) {
        this.attachments.push(ev.target.files[i])
      }
    }
  },
  computed: {
    ...mapState(useMembersStore, [
      'activeUsersBySections',
      'users',
    ]),
    ...mapState(useUserStore, [
      'isDirector'
    ]),
    payload: function () {
      let recipients = {
        'Chor': {tag: 'Choir'},
        'Alle Vereinsmitglieder': {tag: 'Members'},
        'Bass': {tag: 'Section', contents: 'Bass'},
        'Tenor': {tag: 'Section', contents: 'Tenor'},
        'Alt': {tag: 'Section', contents: 'Alt'},
        'Sopran': {tag: 'Section', contents: 'Sopran'},
        'Einzelne': {tag: 'Users', contents: this.recipientIds}
      }[this.recipientType]
      let formData = new FormData()
      this.attachments.forEach(attach => formData.append('attachment', attach))
      formData.append('recipients', JSON.stringify(recipients))
      formData.append('subject', this.subject)
      formData.append('body', this.body)
      return formData
    },
    validationErr: function () {
      if (this.recipientType === 'Einzelne' && !this.recipientIds) {
        return 'Keine Empfänger ausgewählt'
      }
      if (this.body === '') {
        return 'Kein Inhalt'
      }
      if (this.subject === '') {
        return 'Kein Titel'
      }
      return ''
    },
    endpoint: function () {
      return this.sendAsDirector ? '/mail/sendDirector' : '/mail/sendNormal'
    },
    formdata: function () {
      return {
        endpoint: this.endpoint,
        submitText: 'Senden',
        payload: this.payload,
        validationErr: this.validationErr
      }
    }
  }
}
</script>

<style>

.checkboxes > div {
  margin-top: 0.2em;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-right: -1em;
}

.row > div {
  /* Have spacing between the elements, unfortunately we have to
    subtract the last space in the parent */
  margin-right: 1em;
}

#recipients {
  flex-grow: 1;
}
</style>